import { CustomShipmentConfigDto } from 'types/dtos/shipment-custom-config'
import { CustomShipmentConfigModel } from 'types/models/shipment-custom-config'

import { transformCurrencyAmountDto } from './currency-amount'

export const transformCustomShipmentDto = ({
  prices,
}: CustomShipmentConfigDto): CustomShipmentConfigModel => ({
  prices: {
    minimum: transformCurrencyAmountDto(prices.minimum),
    maximum: transformCurrencyAmountDto(prices.maximum),
  },
})
