import { TermsAndConditionsDto } from 'types/dtos/terms-and-conditions'
import { TermsAndConditionsModel } from 'types/models/terms-and-conditions'

export const transformTermsAndConditionsDto = (
  dto: TermsAndConditionsDto,
): TermsAndConditionsModel => ({
  canAgree: dto.can_agree,
  canSkip: dto.can_skip,
  canAccept: dto.can_accept,
  agreementsUrl: dto.agreements_url,
})
