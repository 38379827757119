import { transformItemPhotoDto } from '@marketplace-web/domain/item-data'
import { MyOrderDto } from 'types/dtos/my-order'
import { MyOrderModel } from 'types/models/my-order'

import { transformCurrencyAmountDto } from './currency-amount'

export const transformMyOrderDto = ({
  transaction_id,
  conversation_id,
  date,
  photo,
  price,
  status,
  title,
  transaction_user_status,
}: MyOrderDto): MyOrderModel => ({
  transactionId: transaction_id,
  conversationId: conversation_id,
  date,
  photo: photo ? transformItemPhotoDto(photo) : null,
  price: transformCurrencyAmountDto(price),
  status,
  title,
  transactionUserStatus: transaction_user_status,
})
