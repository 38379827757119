import {
  RequestReturnDetailsDto,
  RequestReturnDetailsPaymentOptionDto,
  RequestReturnDetailsPickupPointDetailsDto,
  RequestReturnDetailsPickupPointDetailsDiscountDto,
} from 'types/dtos/request-return-details'
import {
  RequestReturnDetailsModel,
  RequestReturnDetailsPaymentOptionModel,
  RequestReturnDetailsPickupPointDetailsModel,
  RequestReturnDetailsPickupPointDetailsDiscountModel,
} from 'types/models/request-return-details'

import { transformAddress } from './address'
import { transformCurrencyAmountDto } from './currency-amount'

export const transformRequestReturnDetailsPickupPointDetailsDiscountDto = ({
  percent,
  note,
}: RequestReturnDetailsPickupPointDetailsDiscountDto): RequestReturnDetailsPickupPointDetailsDiscountModel => ({
  percent,
  note,
})

export const transformRequestReturnDetailsPickupPointDto = ({
  pickup_point_code,
  carrier_logo_url,
  carrier_name,
  price,
  pickup_point_name,
  pickup_point_address,
  value_proposition,
  discount,
  price_before_discount,
  lowest_price_in_30_days,
}: RequestReturnDetailsPickupPointDetailsDto): RequestReturnDetailsPickupPointDetailsModel => ({
  pickupPointCode: pickup_point_code,
  carrierLogoUrl: carrier_logo_url,
  carrierName: carrier_name,
  price: transformCurrencyAmountDto(price),
  pickupPointName: pickup_point_name,
  pickupPointAddress: pickup_point_address,
  valueProposition: value_proposition,
  discount: discount && transformRequestReturnDetailsPickupPointDetailsDiscountDto(discount),
  priceBeforeDiscount: price_before_discount && transformCurrencyAmountDto(price_before_discount),
  lowestPriceIn30Days:
    lowest_price_in_30_days && transformCurrencyAmountDto(lowest_price_in_30_days),
})

export const transformRequestReturnDetailsPaymentOptionDto = ({
  code,
  title,
  subtitle,
}: RequestReturnDetailsPaymentOptionDto): RequestReturnDetailsPaymentOptionModel => ({
  code,
  title,
  subtitle,
})

export const transformRequestReturnDetailsPaymentOptionsDto = (
  options: Array<RequestReturnDetailsPaymentOptionDto>,
): Array<RequestReturnDetailsPaymentOptionModel> =>
  options.map(transformRequestReturnDetailsPaymentOptionDto)

export const transformRequestReturnDetailsDto = ({
  photo_url,
  user_address,
  phone_number,
  phone_number_requirement,
  explanation_note,
  payer_selection,
  pickup_point_details,
  package_type_id,
  with_authenticity_check,
  with_electronics_verification,
  shipping_order_id,
}: RequestReturnDetailsDto): RequestReturnDetailsModel => ({
  photoUrl: photo_url,
  userAddress: user_address && transformAddress(user_address),
  phoneNumber: phone_number,
  phoneNumberRequirement: phone_number_requirement,
  explanationNote: explanation_note,
  payerSelection:
    payer_selection && transformRequestReturnDetailsPaymentOptionsDto(payer_selection),
  pickupPointDetails:
    pickup_point_details && transformRequestReturnDetailsPickupPointDto(pickup_point_details),
  packageTypeId: package_type_id,
  withAuthenticityCheck: !!with_authenticity_check,
  withElectronicsVerification: !!with_electronics_verification,
  shippingOrderId: shipping_order_id,
})
