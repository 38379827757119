import {
  LabelOptionsDto,
  LabelOptionsCarrierDto,
  LabelOptionsSenderDto,
  LabelOptionsReceiverDto,
  LabelOptionsAvailableLabelTypesDto,
  LabelOptionsDropOffTypesDto,
  LabelOptionsDropOffTypeMetaDto,
  LabelOptionsDropOffPrintIconDto,
  LabelOptionsDropOffsDto,
} from 'types/dtos/label-options'
import {
  LabelOptionsModel,
  LabelOptionsCarrierModel,
  LabelOptionsSenderModel,
  LabelOptionsReceiverModel,
  LabelOptionsAvailableLabelTypesModel,
  LabelOptionsDropOffTypesModel,
  LabelOptionsDropOffTypeMetaModel,
  LabelOptionsDropOffPrintIconModel,
  LabelOptionsDropOffsModel,
} from 'types/models/label-options'

export const transformLabelOptionsDropOffs = ({
  type,
}: LabelOptionsDropOffsDto): LabelOptionsDropOffsModel => ({
  type,
})

export const transformLabelOptionsDropOffPrintIcon = (
  printIcon: LabelOptionsDropOffPrintIconDto,
): LabelOptionsDropOffPrintIconModel => ({
  url: printIcon.url,
  text: printIcon.text,
})

export const transformLabelOptionsDropOffTypeMeta = ({
  icon_url,
  confirm_title,
  confirm_message,
  secondary_title,
  confirm_selection_title,
  cancel_selection_title,
  print_icon,
}: LabelOptionsDropOffTypeMetaDto): LabelOptionsDropOffTypeMetaModel => ({
  iconUrl: icon_url,
  confirmTitle: confirm_title,
  confirmMessage: confirm_message,
  secondaryTitle: secondary_title,
  confirmSelectionTitle: confirm_selection_title,
  cancelSelectionTitle: cancel_selection_title,
  printIcon: print_icon ? transformLabelOptionsDropOffPrintIcon(print_icon) : null,
})

export const transformLabelOptionsDropOffTypes = ({
  id,
  instructions,
  key,
  title,
  subtitle,
  pick_up_date_shown,
  pick_up_time_shown,
  drop_off_type_meta,
}: LabelOptionsDropOffTypesDto): LabelOptionsDropOffTypesModel => ({
  id,
  instructions,
  key,
  title,
  subtitle,
  pickUpDateShown: pick_up_date_shown,
  pickUpTimeShown: pick_up_time_shown,
  dropOffTypeMeta: transformLabelOptionsDropOffTypeMeta(drop_off_type_meta),
})

export const transformLabelOptionsAvailableLabelTypes = ({
  type,
  title,
  subtitle,
  icon_url,
}: LabelOptionsAvailableLabelTypesDto): LabelOptionsAvailableLabelTypesModel => ({
  type,
  title,
  subtitle,
  iconUrl: icon_url,
})

export const transformLabelOptionsReceiver = ({
  name,
  photo_url,
}: LabelOptionsReceiverDto): LabelOptionsReceiverModel => ({
  name,
  photoUrl: photo_url,
})

export const transformLabelOptionsSender = ({
  phone_number,
  phone_number_requirement,
  photo_url,
}: LabelOptionsSenderDto): LabelOptionsSenderModel => ({
  phoneNumber: phone_number,
  phoneNumberRequirement: phone_number_requirement,
  photoUrl: photo_url,
})

export const transformLabelOptionsCarrier = ({
  name,
  icon_url,
  terms_and_conditions,
}: LabelOptionsCarrierDto): LabelOptionsCarrierModel => ({
  name,
  iconUrl: icon_url,
  termsAndConditions: terms_and_conditions,
})

export const transformLabelOptions = ({
  screen_title,
  action_title,
  drop_off_map_enabled,
  shipment_entry_type,
  carrier,
  sender,
  receiver,
  available_label_types,
  drop_off_types,
  info_banner_text,
  info_banner_type,
  drop_offs,
  shipping_order_id,
}: LabelOptionsDto): LabelOptionsModel => ({
  screenTitle: screen_title,
  actionTitle: action_title,
  dropOffMapEnabled: drop_off_map_enabled,
  shipmentEntryType: shipment_entry_type,
  carrier: transformLabelOptionsCarrier(carrier),
  sender: transformLabelOptionsSender(sender),
  receiver: transformLabelOptionsReceiver(receiver),
  availableLabelTypes: available_label_types
    ? available_label_types.map(transformLabelOptionsAvailableLabelTypes)
    : null,
  dropOffTypes: drop_off_types ? drop_off_types.map(transformLabelOptionsDropOffTypes) : null,
  infoBannerText: info_banner_text,
  infoBannerType: info_banner_type,
  dropOffs: drop_offs ? drop_offs.map(transformLabelOptionsDropOffs) : undefined,
  shippingOrderId: shipping_order_id,
})
