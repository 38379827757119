import { VideoGameRatingDto } from 'types/dtos/video-game-rating'
import { VideoGameRatingModel } from 'types/models/video-game-rating'

export const transformVideoGameRatingDto = (
  videoGameRating: VideoGameRatingDto,
): VideoGameRatingModel => {
  const { id, title } = videoGameRating

  return {
    id,
    title,
  }
}

export const transformVideoGameRatingDtos = (
  videoGameRatings: Array<VideoGameRatingDto>,
): Array<VideoGameRatingModel> => videoGameRatings.map(transformVideoGameRatingDto)
