import { DropOffTypeDto, DropOffTypeMetaDto } from 'types/dtos/drop-off-type'
import { DropOffTypeModel, DropOffTypeMetaModel } from 'types/models/drop-off-type'

import { transformDropOffPrintIcon } from './drop-off-print-icon'

export const transformDropOffTypeMeta = ({
  icon_url,
  confirm_title,
  confirm_message,
  secondary_title,
  confirm_selection_title,
  cancel_selection_title,
  print_icon,
}: DropOffTypeMetaDto): DropOffTypeMetaModel => ({
  iconUrl: icon_url,
  confirmTitle: confirm_title,
  confirmMessage: confirm_message,
  secondaryTitle: secondary_title,
  confirmSelectionTitle: confirm_selection_title,
  cancelSelectionTitle: cancel_selection_title,
  dropOffPrintIcon: print_icon ? transformDropOffPrintIcon(print_icon) : null,
})

export const transformDropOffType = ({
  id,
  instructions,
  key,
  subtitle,
  title,
  drop_off_type_meta,
  pick_up_date_shown,
  pick_up_time_shown,
}: DropOffTypeDto): DropOffTypeModel => ({
  id,
  instructions,
  key,
  subtitle,
  title,
  dropOffTypeMeta: transformDropOffTypeMeta(drop_off_type_meta),
  pickUpDateShown: pick_up_date_shown,
  pickUpTimeShown: pick_up_time_shown,
})

export const transformDropOffTypes = (data: Array<DropOffTypeDto>): Array<DropOffTypeModel> =>
  data.map(transformDropOffType)
