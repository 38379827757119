import { TermsAndConditionsContentDto } from 'types/dtos/terms-and-conditions-content'
import { TermsAndConditionsContentModel } from 'types/models/terms-and-conditions-content'

export const transformTermsAndConditionsContentDto = (
  dto: TermsAndConditionsContentDto,
): TermsAndConditionsContentModel => ({
  title: dto.title,
  text: dto.text,
  updates: {
    title: dto.updates?.title,
    text: dto.updates?.text,
  },
  additionalActions: dto?.additional_actions,
})
