import { transformPaginationDto } from '@marketplace-web/domain/pagination-data'
import { UserBundleItemsResp } from 'types/api/response/user-bundle-items-resp'
import { UserBundleItemsModel } from 'types/models/user-bundle-items'

import { transformBundleItemDtosToProductItems } from './product-item'

export const transformUserBundleItems = (response: UserBundleItemsResp): UserBundleItemsModel => ({
  items: transformBundleItemDtosToProductItems(response.items),
  pagination: transformPaginationDto(response.pagination),
})
