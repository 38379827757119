import { GlobalEmailNotificationsSetting } from 'constants/notification-settings'
import { NotificationSettingsDto } from 'types/dtos/notification-settings'
import { NotificationSettingsSectionModel } from 'types/models/notification-settings'

export const transformNotificationSettingDto = (
  setting: NotificationSettingsDto,
): NotificationSettingsSectionModel => {
  const isGlobalSection = setting.name === GlobalEmailNotificationsSetting.Section

  return {
    isGlobalSection,
    id: setting.name,
    title: setting.type === 'invisible_header' ? undefined : setting.title,
    settings: setting.value.map(settingItem => ({
      id: settingItem.name,
      title: settingItem.title,
      description: settingItem.description,
      isGlobalEmailSwitch:
        isGlobalSection && settingItem.name === GlobalEmailNotificationsSetting.Setting,
      enabled: settingItem.value_options ? false : Boolean(settingItem.value),
      options: settingItem.value_options?.map(({ value, title }) => ({
        value: value || 0,
        label: title || '',
      })),
      value: settingItem.value,
    })),
  }
}
export const transformNotificationSettingsDto = (
  settings: ReadonlyArray<NotificationSettingsDto>,
): ReadonlyArray<NotificationSettingsSectionModel> => settings.map(transformNotificationSettingDto)
