import {
  BannerLayoutDto,
  BannersBlockDto,
  LayoutElementDto,
  ThumbnailsBlockDto,
} from 'types/dtos/homepage-layouts'
import {
  BannerLayoutModel,
  BannersBlockModel,
  LayoutElementModel,
  ThumbnailsBlockModel,
} from 'types/models/homepage-layouts'

import { ALLOWED_BUTTON_THEMES, ALLOWED_TEXT_THEMES } from 'constants/home'

import { transformPhotoDto } from './photo'

export const transformLayoutElement = (
  thumbnails: Array<LayoutElementDto>,
): Array<LayoutElementModel> => {
  if (!thumbnails) return []

  return thumbnails.map(thumbnail => ({
    id: thumbnail.id,
    title: thumbnail.title,
    photo: transformPhotoDto(thumbnail.photo),
    contentSource: thumbnail.content_source,
    cta: {
      uri: thumbnail.cta.uri,
      url: thumbnail.cta.url,
      accessibilityLabel: thumbnail.cta.accessibility_label,
      title: thumbnail.cta.title,
    },
  }))
}

export const transformThumbnailsBlock = (block: ThumbnailsBlockDto): ThumbnailsBlockModel => ({
  id: block.id,
  title: block.title,
  name: block.name,
  style: block.style,
  subtitle: block.subtitle,
  elements: transformLayoutElement(block.elements),
})

export const transformBannersBlockElements = (
  banners: Array<BannerLayoutDto>,
): Array<BannerLayoutModel> => {
  if (!banners) return []

  return banners
    .filter(
      banner =>
        ALLOWED_TEXT_THEMES.includes(banner.text_theme) &&
        ALLOWED_BUTTON_THEMES.includes(banner.cta.theme),
    )
    .map(banner => {
      return {
        id: banner.id,
        title: banner.title,
        description: banner.description,
        contentSource: banner.content_source,
        background: transformPhotoDto(banner.background),
        foreground: banner.foreground ? transformPhotoDto(banner.foreground) : null,
        textTheme: banner.text_theme,
        icon: banner.icon ? transformPhotoDto(banner.icon) : null,
        cta: {
          url: banner.cta.url,
          accessibilityLabel: banner.cta.accessibility_label,
          title: banner.cta.title,
          theme: banner.cta.theme,
          inverse: banner.cta.inverse,
        },
      }
    })
}

export const transformBannersBlock = (block: BannersBlockDto): BannersBlockModel => ({
  id: block.id,
  name: block.name,
  elements: transformBannersBlockElements(block.elements),
})
