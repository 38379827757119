import { ItemPerformanceModel } from 'types/models/item-performance'
import { ItemPerformanceDto } from 'types/dtos/item-performance'

export const transformItemPerformance = (dto: ItemPerformanceDto): ItemPerformanceModel => ({
  impressions: dto.impressions,
  favorites: dto.favorites,
  views: dto.views,
  conversations: dto.conversations,
  humanizedHoursLeft: dto.humanized_hours_left,
  promotionEndedDaysAgo: dto.promotion_ended_days_ago,
  recentlyPromoted: dto.recently_promoted,
  impressionDifference: dto.impression_difference,
  chartData: dto.chart_data,
})
