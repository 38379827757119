import { transformItemPhotoDtos } from '@marketplace-web/domain/item-data'
import { ReserveItemDetailsDto } from 'types/dtos/reserve-item'
import { ReserveItemDetailsModel } from 'types/models/reserve-item'

export const transformReserveItemDetailsDto = (
  dto: ReserveItemDetailsDto,
): ReserveItemDetailsModel => ({
  id: dto.id,
  title: dto.title,
  photos: transformItemPhotoDtos(dto.photos),
})
