import { AvailableCarrierDto } from 'types/dtos/available-carrier'
import { AvailableCarrierModel } from 'types/models/available-carrier'

export const transformAvailableCarrierDto = (
  availableCarrier: AvailableCarrierDto,
): AvailableCarrierModel => ({
  rootRateUuid: availableCarrier.root_rate_uuid,
  title: availableCarrier.title,
  firstMileCarrier: {
    code: availableCarrier.first_mile_carrier.code,
    name: availableCarrier.first_mile_carrier.name,
    iconUrl: availableCarrier.first_mile_carrier.icon_url,
    dropOffPointsUrl: availableCarrier.first_mile_carrier.drop_off_points_url,
  },
  lastMileCarrier: {
    code: availableCarrier.last_mile_carrier.code,
    name: availableCarrier.last_mile_carrier.name,
    iconUrl: availableCarrier.last_mile_carrier.icon_url,
  },
})

export const transformAvailableCarrierDtos = (
  availableCarriers: Array<AvailableCarrierDto>,
): Array<AvailableCarrierModel> => availableCarriers.map(transformAvailableCarrierDto)
