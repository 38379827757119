import { PackageSizeDto } from 'types/dtos/package-size'
import { PackageSizeModel } from 'types/models/package-size'

export const transformPackageSizeDto = ({
  code,
  custom,
  name,
  description,
  education_text,
  format_description,
  id,
  standard,
  title,
  weight_description,
  image,
}: PackageSizeDto): PackageSizeModel => ({
  code,
  custom,
  name,
  description,
  educationText: education_text,
  formatDescription: format_description,
  id,
  standard,
  title,
  weightDescription: weight_description,
  image,
})

export const transformPackageSizeDtosToModels = (
  packageSizeDtos: Array<PackageSizeDto>,
): Array<PackageSizeModel> => packageSizeDtos.map(transformPackageSizeDto)
