import {
  ShippingContactConfigurationDto,
  ShippingContactValidationDto,
} from 'types/dtos/shipping-contact'
import {
  ShippingContactConfigurationModel,
  ShippingContactValidationModel,
} from 'types/models/shipping-contact'

export const transformShippingContactValidationDto = (
  dto: ShippingContactValidationDto,
): ShippingContactValidationModel => ({
  regex: dto.regex,
  message: dto.message,
  trackingTarget: dto.tracking_target,
})

export const transformShippingContactConfigurationDto = (
  dto: ShippingContactConfigurationDto,
): ShippingContactConfigurationModel => ({
  phoneNumber: {
    validations: dto.phone_number?.validations.map(transformShippingContactValidationDto) || [],
  },
})
