import { CarrierDto, RestrictionDto } from 'types/dtos/carrier'

import { CarrierModel, RestrictionModel } from 'types/models/carrier'

export const transformRestrictionDto = (
  restriction: RestrictionDto | null,
): RestrictionModel | null => {
  if (!restriction) return null

  return {
    status: restriction.status,
    message: restriction.message,
  }
}

export const transformCarrierDto = ({
  id,
  untracked,
  custom,
  name,
  mandatory,
  preferred_choice,
  buyer_hint,
  code,
  description,
  formatted_address_title,
  delivery_type,
  shipment_delivery_type,
  buyer_title,
  no_shipping,
  icon_url,
  restriction,
  shipment_instructions,
}: CarrierDto): CarrierModel => ({
  id,
  name,
  untracked,
  custom,
  code,
  description,
  isMandatory: mandatory,
  isPreferredChoice: preferred_choice,
  buyerTitle: buyer_title,
  buyerHint: buyer_hint,
  formattedAddressTitle: formatted_address_title,
  deliveryType: delivery_type,
  shipmentDeliveryType: shipment_delivery_type,
  noShipping: no_shipping,
  iconUrl: icon_url,
  restriction: transformRestrictionDto(restriction),
  shipmentInstructions: shipment_instructions,
})

export const transformCarriersDto = (carriers: Array<CarrierDto>): Array<CarrierModel> =>
  carriers.map(transformCarrierDto)
