import {
  SellerOfferPriceSuggestionDto,
  SellerOfferOptionsDto,
} from 'types/dtos/seller-offer-options'
import {
  SellerOfferPriceSuggestionModel,
  SellerOfferOptionsModel,
} from 'types/models/seller-offer-options'

import { transformCurrencyAmountDto } from './currency-amount'

const transformOfferPriceSuggestion = ({
  label,
  price,
}: SellerOfferPriceSuggestionDto): SellerOfferPriceSuggestionModel => ({
  label,
  price: transformCurrencyAmountDto(price),
})

export const transformSellerOfferOptionsDto = (
  sellerOfferOptions: SellerOfferOptionsDto,
): SellerOfferOptionsModel => ({
  minPrice: transformCurrencyAmountDto(sellerOfferOptions.min_price),
  maxPrice: transformCurrencyAmountDto(sellerOfferOptions.max_price),
  offerSuggestions:
    sellerOfferOptions.offer_suggestions?.map(transformOfferPriceSuggestion) || null,
  feesReductionThreshold: sellerOfferOptions.fees_reduction_threshold
    ? transformCurrencyAmountDto(sellerOfferOptions.fees_reduction_threshold)
    : null,
})
