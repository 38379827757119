import {
  TrackingJourneySummaryCarrierDto,
  TrackingJourneySummaryDetailsDto,
  TrackingJourneySummaryDto,
  TrackingJourneySummaryCurrentCarrierDto,
  TrackingJourneySummaryEstimatedDetailDto,
} from 'types/dtos/tracking-journey-summary'
import {
  TrackingJourneySummary,
  TrackingJourneySummaryCarrier,
  TrackingJourneySummaryCurrentCarrier,
  TrackingJourneySummaryDetails,
  TrackingJourneySummaryEstimatedDetail,
} from 'types/models/tracking-journey-summary'

export const transformEstimatedDetail = (
  estimatedDetail: TrackingJourneySummaryEstimatedDetailDto,
): TrackingJourneySummaryEstimatedDetail => ({
  status: estimatedDetail.status,
  timePeriod: estimatedDetail.time_period,
  message: estimatedDetail.message,
})

export const transformCurrentCarrier = (
  currentCarrier: TrackingJourneySummaryCurrentCarrierDto,
): TrackingJourneySummaryCurrentCarrier => ({
  id: currentCarrier.id,
  priority: currentCarrier.priority,
  name: currentCarrier.name,
  trackingCode: currentCarrier.tracking_code,
  trackingUrl: currentCarrier.tracking_url,
})

export const transformTrackingJourneySummaryCarrier = (
  carrier: TrackingJourneySummaryCarrierDto,
): TrackingJourneySummaryCarrier => ({
  id: carrier.id,
  logoUrl: carrier.logo_url,
  priority: carrier.priority,
  trackingCode: carrier.tracking_code,
  trackingUrl: carrier.tracking_url,
})

export const transformTrackingJourneySummaryDetails = (
  trackingDetail: TrackingJourneySummaryDetailsDto,
): TrackingJourneySummaryDetails => ({
  message: trackingDetail.message,
  timestamp: trackingDetail.timestamp,
  carrier: trackingDetail.carrier
    ? transformTrackingJourneySummaryCarrier(trackingDetail.carrier)
    : null,
  cellType: trackingDetail.cell_type,
})

export const transformTrackingJourneySummary = (
  response: TrackingJourneySummaryDto,
): TrackingJourneySummary => ({
  details: response.details.map(transformTrackingJourneySummaryDetails),
  canMarkAsReceived:
    response.actions.includes('mark_as_received') || response.actions.includes('mark_as_delivered'),
  canMarkAsReturnedToSender: response.actions.includes('complete_return_to_sender'),
  currentCarrier: response.current_carrier && transformCurrentCarrier(response.current_carrier),
  estimatedDetail: response.estimated_detail && transformEstimatedDetail(response.estimated_detail),
  userSide: response.user_side,
})
